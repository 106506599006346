<template>
  <certificates 
    :loading="loading"
    :isSelfRetail ="true" 
    :certificate="certificate"
    :certificatesHistory="certificatesHistory"
    :certificateHistoryTotal="certificateHistoryTotal"
    :activeCertificateGuid="activeCertificateGuid"
    :manager="manager"
    :pdfHref="pdfHref"
    :isParentCertificateRevoked="isParentCertificateRevoked"
    :isCertificationProcess="isCertificationProcess"
    :hasActiveRequest="hasActiveRequest"
    :certificationRequests="certificationRequests"
    @approve-certificate="onApprovedCertification"
    @reject-certificate="onRejectCertification"
    @request-certificate="onRequestCertificate"
    @toggle-manufacturer-info="onToggleManufacturerInfo"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Certificates } from '@/components'

export default {
  name: "SelfRetailsCertificates",
  components: { Certificates },
  data() {
    return {
      loading: false,
      pdfHref: "",
      isCertificationProcess: false,
      certificationRequests: null
    }
  },
  methods: {
    ...mapActions('certificates', [
      "fetchSelfRetailCertificate",
      "fetchSelfRetailCertificateHistory",
      "fetchSelfRetail",
      "createSelfRetailCertify",
      "fetchSelfretailHasActiveRequest",
      "fetchManufacturerCertificate"
    ]),

    onApprovedCertification(id) {
      this.loading = true;
      this.approveDealerCertificate(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },
    onRejectCertification(id) {
      this.loading = true;
      this.rejectDealerCertificate(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },

    onRequestCertificate() {
      this.createSelfRetailCertify({id: this.partnerId, retailId: this.$route.params.id}).then(() => {
        this.fetchAllData()
      });
    },

    onToggleManufacturerInfo() {
      console.log('onToggleManufacturerInfo')
    },

    fetchAllData() {
      this.fetchManufacturerCertificate()
      this.fetchSelfretailHasActiveRequest(this.$route.params.id)
      this.fetchSelfRetailCertificate(this.$route.params.id).then(() => {        
        this.pdfHref = this.certificate ? process.env.VUE_APP_API + '/selfretailcertificates/pdf/' + this.certificate.key : null;
      });
      this.fetchSelfRetailCertificateHistory(this.$route.params.id);
    }

  },
  computed: {
    ...mapState({partnerId: state => state.auth.user.partnerId}),
    ...mapState('certificates', {      
      certificate: state => state.selfretailCertificate,
      certificatesHistory: state => state.selfretailCertificateHistory,
      certificateHistoryTotal: state => state.selfretailCertificateHistoryTotal,
      activeCertificateGuid: state => state.activeCertificateGuid,
      manager: state => state.manufacturerManager,
      hasActiveRequest: state => state.isSelfretailHasActiveRequest,
      isParentCertificateRevoked: state => state.certificate?.isRevoked
    })
  },  
  created() {
    this.fetchAllData()
  },
  
}
</script>

<style lang="scss" scoped></style>